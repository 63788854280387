

@font-face {
  font-family: 'Helvetica-Regular';
  src: url(../public/assets/fonts/HELVETICA\ NEUE\ LT\ PRO\ 55\ ROMAN.OTF);
}

@font-face {
  font-family: 'Helvetica-Medium';
  src: url(../public/assets/fonts/HELVETICA\ NEUE\ LT\ PRO\ 65\ MEDIUM.OTF);
}


@font-face {
  font-family: 'Helvetica-Bold';
  src: url(../public/assets/fonts/Helvetica\ Neue\ LT\ Pro\ 75\ Bold.otf);
}

@font-face {
  font-family: 'Helvetica-ExtraBold';
  src: url(../public/assets/fonts/HelveticaNeueLTPro-BdOu.otf);
}


#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica-Medium';
  background-color: #000104;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

h2{
  font-weight: 700;
font-size: 48px;
line-height: 58px;
font-family: 'Helvetica-Bold';
color: white;
}
/* Header  */

.navbar {
  height: 85px;
  position: fixed;
  width: 100%;
  z-index: 11;
}

.navbar .nav-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
}

.navbar .nav-link:hover {
  color: #dae0ff;
  transition: all .2s ease-in-out;
}

.nav-ic {
  background: linear-gradient(45deg, #5500FF 0%, rgba(85, 0, 255, 0) 100%), #0090BF;
  border-radius: 40px;
  display: inline-flex;
  font-size: 24px;
  text-align: center;
  color: rgb(218, 224, 255);
  height: 40px;
  width: 40px;
  padding: 1px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.nav-ic:hover {
  background: linear-gradient(180deg, rgba(0, 42, 255, 0.5) 0%, rgba(0, 42, 255, 0) 100%), #FFFFFF;
  color: #000817;
  transition: all .1s ease-in-out;
}

.nav-ic .icon {
  background: #0E0504;
  display: inline-flex !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  height: 100%;
  transition: all .3s ease-in-out;

}

.nav-ic:hover .icon {
  background: transparent;
  transition: all .3s ease-in-out;

}


.navbar-collapse.collapsing {
  transition: none !important;
}

.h-40{
  height: 40px !important;
}

/* Banner */

.banner {
  padding-top: 120px;
  padding-bottom: 200px;
  overflow: auto;
  display: flex;
  align-items: center;
  background-image: url(../public/assets/bg1.png);
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
}

.banner h1 {
  font-weight: 700;
  font-size: 80px;
  line-height: 112px;
  color: #fff;
  
}

.banner p {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: rgba(255, 255, 255, .5);
}

.primary-btn {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background: linear-gradient(45deg, #5500FF 0%, rgba(85, 0, 255, 0) 100%), #0090BF;
  border-radius: 8px;
  height: 64px;
  width: 170px;
  padding: 14px 32px;
  letter-spacing: 1px;
text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn:hover {
  background: linear-gradient(180deg, #5500FF 0%, rgba(85, 0, 255, 0) 100%), #0090BF;
  transition: all .3s ease-in-out;
  color: white;
}

.primary-btn-outline {
  background: linear-gradient(45deg, #5500FF 0%, rgba(85, 0, 255, 0) 100%), #0090BF;
  border-radius: 8px;
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: white;
  height: 64px;
  max-height: 64px;
  width: 170px;
  padding: 1px;
  letter-spacing: 1px;
text-transform: uppercase;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.primary-btn-outline:hover {
  background: linear-gradient(45deg, #5500FF 0%, rgba(85, 0, 255, 0) 100%), #0090BF;
  color: #fff;
  transition: all .1s ease-in-out;
}

.primary-btn-outline span {
  background: #0E0504;
  display: inline-flex !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 100%;
  transition: all .3s ease-in-out;

}

.primary-btn-outline:hover span {
  background: transparent !important;
  transition: all .3s ease-in-out;

}

.primary-badge{
  color: white;
    padding: 13px 25px;
    background: linear-gradient(45deg, rgba(85, 0, 255, 0.3) 0%, rgba(85, 0, 255, 0) 100%), rgba(0, 144, 191, 0.3);
    background-blend-mode: color, normal;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    text-transform: uppercase;
}

.left-aside,
.right-aside {
  width: 172px;
  margin-top: 60px;
  position: relative;
}

.left-aside p,
.right-aside p {
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  color: #808080;
  margin-bottom: 0px;
}

.left-aside::before {
  content: "";
  position: absolute;
  right: -10px;
  height: 70%;
  background: #808080;
  width: 2px;

}

.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}


@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

}


@media(max-width: 767px) {

  .navbar {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .banner {
    position: relative;
    z-index: 0;
    align-items: flex-start;
    height: auto;
    padding-top: 0;
    background-size: cover;
    background-position: 75%;
    padding-bottom: 50px;
  }

  .banner h1 {
    margin-bottom: 20px;
    font-size: 46px;
    line-height: 60px;
    padding-top: 0px;
  }
  .banner .primary-badge {
   margin-top: 120px;
  }

  .banner p {
    font-size: 20px;
    line-height: 30px;
  }

  .banner::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000000c4;
    position: absolute;
    z-index: 0;
    overflow-y: auto;
  }

  .banner p {
    margin-bottom: 35px;
  }

  .left-aside,
  .right-aside {
    margin-top: 45px;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 111;
    display: block !important;
  }

  .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 11;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 32px;
  }

}




/* feature cards  */

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.feature-card {
  background: linear-gradient(45deg, rgba(85, 0, 255, 0.25) 0%, rgba(85, 0, 255, 0) 100%), linear-gradient(0deg, rgba(0, 144, 191, 0.25), rgba(0, 144, 191, 0.25)), rgba(0, 0, 0, 0.5);
  background-blend-mode: color, normal, normal;
  backdrop-filter: blur(12px);
  border-radius: 8px;
  padding: 20px;
  height: 100%;
}

.feature-img {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

.feature-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.feature-card h4 {
  font-family: 'Helvetica-Bold';
  font-weight: 700;
font-size: 32px;
line-height: 38px;
  color: white;
  margin-bottom: 15px;
}

.feature-card p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, .7);
}


/* how-it-work  */

.how-it-work h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, .7);
  margin-bottom: 20px;
}

.how-it-work h2 {
  font-weight: 700;
font-size: 48px;
line-height: 58px;
color: #fff;
  margin-bottom: 20px;
}

.how-it-work p {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: rgba(255, 255, 255, .5);
  margin-bottom: 20px;

}

/* .partner css  */

.partner-img{
  margin-bottom: 25px;
  text-align: center;
}
.partner-img img{
  mix-blend-mode: luminosity;
border-radius: 12px;
}
.p-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}
.partner-img .img12{
  height: 100%;
  width: 100%;
object-fit: contain;
}

/* footer  */

footer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
.footer-link:hover{
  color: #dae0ff;
}

.footer-link .iconify{
  color: #5500FF;
  font-size: 32px;
}
p.footer-link{
  color: rgba(255, 255, 255,.5);
}

.av-bg{
  padding-top: 120px;
  padding-bottom: 200px;
  overflow: auto;
  display: flex;
  align-items: center;
  background-image: url(../public/assets/bg2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.av-bg p{
  color: rgba(255, 255, 255, .5);
  font-family: 'Helvetica-Regular';
  font-weight: 400;
  margin-bottom: 40px;
}
.av-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45px, 1fr));
  grid-gap: 40px;
  align-items: stretch;
}
.tooltip{
  --bs-tooltip-max-width: 320px !important;
}
.tooltip-inner{
  padding: 0;
  background-color: transparent;
}
.tooltip-arrow{
  display: none !important;
}
@media (max-width: 767px){
  .av-bg {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .av-grid {
    grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
    grid-gap: 20px;
}
  h2 {
    font-size: 42px;
    line-height: 50px;
}
  .how-it-work h2 {
    font-size: 38px;
    line-height: 44px;
  }
 
  .how-it-work p {
    font-size: 20px;
    line-height: 28px;
  }

  .partner-img{
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  .partner-img .img1{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.card-grid .feature-card p{
  font-size: 16px;
  margin-bottom: 10px;
}
.card-grid .feature-card h4{
  font-size: 28px;
  margin-bottom: 0;
}

  .footer-logo{
    margin-bottom: 10px;
  }

  .footer-link.sm-50{
    width: 45%;
  }
  p.footer-link{
    margin-top: 10px;
    color: rgba(255, 255, 255,.5);
  }
}